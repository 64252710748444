import React, { useRef, useEffect } from "react"
import classnames from "classnames/bind"
import Img from "gatsby-image/withIEPolyfill"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import Splitting from "splitting"

// Component
import TextReveal from "./text-reveal"

// Data
import useLandPageData from "../hook/landing_page_data"

// Style
import LandingStyle from "../style/components/landing.module.scss"

/**
 * Animation
 * https://tympanus.net/codrops/2020/06/17/making-stagger-reveal-animations-for-text/
 * */

const Landing = props => {
  const {
    description,
    title,
    heading,
    subtitle,
    landing_image,
  } = useLandPageData()
  const mobile_landing_image = landing_image.thumbnails.MobileOnly

  const heroImageSelfRef = useRef(null)
  const heroImageSmallSelfRef = useRef(null)
  const descDotRef = useRef(null)

  // - Animate
  useEffect(() => {
    // text-reavel.js內的文字也使用ID #spiltText
    const spiltText = document.querySelectorAll("#spiltText")
    Splitting({ target: spiltText, by: "words" })
    // 使用querySelectorAll就可以選擇到text-reavel.js內的文字，一起進行Animation
    const spiltTextItems = document.querySelectorAll(
      "#spiltText .word, .whitespace"
    )
    const descDot = descDotRef.current
    const staggerValue = 0.012
    const charsDuration = 0.6

    gsap
      .timeline()
      .set(spiltTextItems, {
        autoAlpha: 0,
        y: "100%",
      })
      .set(descDot, {
        autoAlpha: 0,
      })
      .set(heroImageSelfRef.current, {
        y: "40%",
        autoAlpha: 0,
      })
      .set(heroImageSmallSelfRef.current, {
        y: "40%",
        autoAlpha: 0,
      })
      .addLabel("startTime")
      // Animate the green dot
      .to(
        descDot,
        {
          autoAlpha: 1,
          delay: 1.2,
        },
        "startTime"
      )
      // Stagger the animation of the words
      .staggerTo(
        spiltTextItems,
        charsDuration,
        {
          ease: "Power3.easeIn",
          y: "0%",
          autoAlpha: 1,
          delay: 0.5,
        },
        staggerValue,
        "startTime"
      )
      // Animate the picture in
      .to(
        heroImageSelfRef.current,
        0.8,
        {
          ease: "Power3.easeOut",
          y: "0%",
          autoAlpha: 1,
          rotation: 0,
        },
        "startTime+=1"
      )
      .to(
        heroImageSmallSelfRef.current,
        0.8,
        {
          ease: "Power3.easeOut",
          y: "0%",
          autoAlpha: 1,
          rotation: 0,
        },
        "startTime+=1"
      )
  }, [])

  return pug`
    div(className=LandingStyle.text_wrapper)
      figure.d-only-mobile.mb-10(className=classnames(LandingStyle.image_wrapper,LandingStyle.fadeIn) ref=heroImageSmallSelfRef)
        Img(fluid=mobile_landing_image.fluid alt=props.image_alt)
      div.overflow-hidden(className=LandingStyle.text_heading)
        p#spiltText.my-0(className=props.darkmode?"text-theme-100":"text-gray-100")=heading.text
      div.overflow-hidden.mt-2.mb-6.flex.items-center.flex-row
        h1#spiltText.txt-display1.my-0(className=props.darkmode?"txt-hasShadow-white-white":"txt-hasShadow-white")=title.text
        figure.d-table-above.ml-12(className=classnames(LandingStyle.image_wrapper,LandingStyle.fadeIn) ref=heroImageSelfRef)
          Img(fluid=landing_image.fluid alt=props.image_alt)
      div.overflow-hidden.mt-3
        TextReveal(darkmode=props.darkmode)
      div.overflow-hidden.mt-1.flex.items-center.flex-row(className=classnames(LandingStyle.text_description,"sm:mt-10"))
        div.mr-2.bg-green(className=LandingStyle.text_description_dot ref=descDotRef)
        p#spiltText.my-0(className=props.darkmode?"text-theme-100":"text-gray-100") #[strong Currently available] for freelance projects.

  `
}

export default Landing
