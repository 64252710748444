import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const useLandPageData = () => {
  const { landPageData } = useStaticQuery(graphql`
    query {
      landPageData: prismicLandingPage {
        id
        data {
          heading {
            text
          }
          title {
            raw
            text
          }
          subtitle {
            text
            raw
          }
          description {
            text
            raw
          }
          landing_image {
            fluid {
              ...GatsbyPrismicImageFluid
            }
            thumbnails {
              MobileOnly {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  return landPageData.data
}

export default useLandPageData
